import { IURLTopology } from '@clubpay/customer-common-module/src/hook/router';
import { IAvailability } from '@clubpay/customer-common-module/src/service/date';
import {
    DiningOptionEnum,
    IRestaurant,
    OrderModeEnum,
} from '@clubpay/customer-common-module/src/repository/vendor/type';

export interface IPaginationPayload {
    limit?: number;
    page?: number;
    scrollId?: string;
}

export interface IMenuPayload extends IURLTopology {
    lang?: string;
    menu?: string;
}

export interface ISessionEditPayload {
    orderId: string;
    reference: string;
    dsi: string;
}

export type INameTranslation = { [key: string]: string };

export interface ICommonMenuItemProp {
    id: string;
    name: string;
    nameTranslations: INameTranslation;
    subTitle: string;
    subTitleTranslations: INameTranslation;
    description: string;
    descriptionTranslations: INameTranslation;
    media?: IMedia[];
    calories?: { min: number; max: number };
    min: number;
    max: number;
    multiMax: number;
    distinctMax: number;
    defaultQuantity: number;
    priceAdditiveName: any;
    priceAdditiveType: any;
    priceAdditiveAmount: any;
    availabilities: IAvailability[];
    tagIds: any;
    status?: MenuItemStatusEnum;
}

export interface IProductSelect {
    qty: number;
}

export enum MenuItemStatusEnum {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export interface IModifierGroupItemCount {
    selectedItems?: number;
    selectedDistinctItems?: number;
    maxSelectedQty?: number;
}

export interface IValid {
    valid?: boolean;
    invalidMgIds?: string[];
}

export interface IModifierGroup<T> extends ICommonMenuItemProp, IModifierGroupItemCount, IValid {
    products?: Array<T>;
    outOfSchedule?: boolean;
    disabled?: boolean;
}

export interface IProductMeta {
    sizeId?: string;
    sizeName?: string;
    displayPrice?: number;
    _media?: IMedia[];
    _calories?: {
        min: number;
        max: number;
    };
    _defaultQty?: number;
}

export enum ProductTypeEnum {
    Bundle = 'BUNDLE',
    Product = 'PRODUCT',
    Additive = 'ADDITIVE',
    Option = 'OPTION',
}

export interface IParseProductOptions {
    vendor?: IRestaurant;
    categoryId?: string;
}

export interface ITags {
    id: string;
    name: string;
    nameTranslations?: INameTranslation;
    group: string;
}

export interface IProduct extends ICommonMenuItemProp, IValid {
    modifierGroups?: Array<IModifierGroup<IProduct>>;
    price: number;
    charges: number;
    type: ProductTypeEnum;
    meta?: IProductMeta;
    menuId: string;
    products?: IProduct[];
    selected?: IProductSelect;
    option?: IProduct;
    hasAdditives?: boolean;
    outOfSchedule?: boolean;
    snoozed?: boolean;
    displayPrice?: string;
    disabled?: boolean;
    tags?: ITags[];
    // only for initializing the temp basket
    mgId?: string;
    categoryId?: string;
    inventory?: number;
}

export interface ICategoryProduct extends IProduct {
    more?: boolean;
}

export interface ICategory extends ICommonMenuItemProp {
    availabilities: IAvailability[];
    id: string;
    ids: string[];
    media?: IMedia[];
    max: number;
    min: number;
    modifierGroups?: Array<IModifierGroup<IProduct>>;
    multiMax: number;
    type: string;
    products: ICategoryProduct[];
    disabled?: boolean;
    outOfSchedule?: boolean;
    size?: number;
    meta: {
        _media: IMedia[];
    };
}

export interface IMenu {
    availabilities: IAvailability[];
    categories: ICategory[];
    description: string;
    descriptionTranslations: INameTranslation;
    externalId: string;
    id: string;
    media?: IMedia[];
    name: string;
    nameTranslations: INameTranslation;
    type: string;
    meta?: any;
    status?: MenuItemStatusEnum;
    disabled?: boolean;
    outOfSchedule?: boolean;
}

export interface IMenuResponse {
    data?: IMenu[];
    status: number;
    timestamp?: number;
}

export interface IMedia {
    optimizedFile?: string;
    originalFile?: string;
    id?: string;
    thumbnail?: {
        url: string;
    };
    status?: MediaStatusEnum;
}

export interface IPagination {
    currentPage: number;
    limit: number;
    pageDataLength: number;
    totalDataLength: number;
    hasMorePage: boolean;
    previousPage: number;
    pageHasData: boolean;
}

export interface IUserInfoMeta extends Record<string, string | undefined> {
    cpf?: string;
    carPlate?: string;
}

export interface IUserInfo {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
    meta?: IUserInfoMeta;
}

export interface IOrderData {
    refId: string;
    orderData: Omit<IOrder, 'vendorId'>;
    customerComment: string;
    userInfo?: IUserInfo;
    totalAmount?: number;
    scheduledAt?: Date;
    recaptcha?: string;
    diningOption?: DiningOptionEnum;
}

export interface IOrderVendorId {
    cc: string;
    slug: string;
}

export interface IOrderTableData {
    paxCount?: number;
}

export interface IOrder {
    items: IOrderItem[];
    extra: any;
    note?: string;
    totalAmount?: number;
    passcode?: string;
    vendorId: IOrderVendorId;
    version?: 'v2';
    lang?: string;
    diningOption?: DiningOptionEnum;
    tableData?: IOrderTableData;
}

export enum OrderItemStatusEnum {
    New = 'new',
    Approved = 'approved',
    Completed = 'completed',
    Rejected = 'rejected',
    PickUp = 'pickUp',
}

export interface IOrderItem extends IAdditiveItem {
    selectionId?: number;
    menuId: string;
    uid?: string;
    timestamp?: number;
    status?: OrderItemStatusEnum;
    note?: string;
    batchNote?: string;
}

export interface IAdditiveItem {
    id: string;
    quantity: number;
    price: number;
    mgId?: string;
    optionId?: string;
    additives?: IAdditiveItem[];
}

export enum OrderPaymentStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Success = 'success',
    Refunded = 'refunded',
}

export interface IOrderMeta {
    dsi?: string;
    reference?: string;
}

export interface IQSRUrlData {
    cc: string;
    slug: string;
    tableId: string;
    f1: string;
    f2: string;
    hash?: string;
}

export interface IQSRTimer {
    active: boolean;
    time?: number;
    acceptedAt?: string;
}

export interface IQSRPaymentDetail {
    bill: number;
    tip: number;
}

interface IQSRServerOrder {
    url: string;
    urlData: IQSRUrlData;
    ticketId: string;
    refId: string;
    orderData: IOrder;
    orderStatus: OrderStatusEnum;
    customerComment: string;
    paymentStatus: string;
    paymentResponse?: IQSRPaymentDetail;
    paidAt?: Date;
    timer?: IQSRTimer;
    approved?: boolean;
    subTotal: number;
    posTotal: number;
    total: number;
    userInfo?: IUserInfo;
    paymentMethod?: OrderPaymentStatusEnum;
    preparationTime?: number;
    diningOption?: DiningOptionEnum;
    scheduledAt?: Date;
    pickupAt?: Date | string;
    createdAt: Date;
    meta: IOrderMeta;
    paymentUrlData?: IQSRUrlData;
}

export interface IQSROrder extends IQSRServerOrder {
    note?: string;
    date?: Date | string;
    batchStatus?: OrderItemStatusEnum;
    batchId?: string;
    batchSort?: boolean;
}

export enum OrderStatusEnum {
    VERIFYING = 'verifying',
    PENDING = 'pending',
    DECLINED = 'declined',
    FAILED = 'failed',
    APPROVED = 'approved',
    PREPARING = 'preparing',
    CANCELLED = 'cancelled',
    DELIVERED = 'delivered',
    ACTIVE = 'active',
    CLOSED = 'closed',
    RETURNED = 'returned',
    JOINED = 'joined',
    VOID = 'void',
    SCHEDULED = 'scheduled',
    PICK_UP = 'pickUp',
    SYNC_FAILED = 'syncFailed',
}

export enum MediaStatusEnum {
    CONFIRMED = 'CONFIRMED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export interface IOrderResponse {
    data: IQSROrder;
}

export interface IQRValidationResponse {
    data: {
        ttl?: number;
        expired: boolean;
    };
    statusCode?: number;
    message?: string;
    error?: string;
}

export enum OrderHistoryStatusEnum {
    All = 'all',
    Active = 'active',
    Today = 'today',
}

export interface IOrderHistoryQueryParams {
    skip?: number;
    limit?: number;
    status?: OrderHistoryStatusEnum;
}

export interface IOrderHistoryResponse {
    data: {
        list: IQSROrder[];
    };
}

export interface IOrderGetActiveCountResponse {
    data: {
        count: number;
    };
}

export type IUserInfoSetRequest = IUserInfo;

export interface IUserInfoResponse {
    userInfo: IUserInfo;
}

export interface IGetProductSelector {
    productId: string;
    menuId: string;
}

export interface IPromise {
    resolve: any;
    reject: any;
    ids: Array<IGetProductSelector>;
}

export interface IRequest {
    promises: IPromise[];
    url: string;
    ids: Array<IGetProductSelector>;
}

export interface IOrderOptions {
    vendor?: IRestaurant;
    multiOrder?: boolean;
    qrpc?: string;
    vendorToken?: string;
    unifyTimestamp?: boolean;
}

export interface ISocialMenuConfig {
    themeMode: string;
    defaultLang: string;
    manualAmount: boolean;
}

export interface ISocialMenu {
    id?: string;
    cc: string;
    slug: string;
    name: string;
    title: string;
    logoSmallUrl?: string;
    logoBigUrl?: string;
    backgroundUrl?: string;
    url: string;
    params: IURLTopology;
    orderMode: OrderModeEnum;
    opsMode: string | null;
    config: ISocialMenuConfig;
}

export interface ISocialMenuResponse {
    status: number;
    data: ISocialMenu[];
    timestamp?: number;
}

export enum StepperStatusEnum {
    BASKET = 'basket',
    CHECKOUT = 'checkout',
    VERIFYING = 'verifying',
    PREPARING = 'preparing',
    ENJOY = 'enjoy',
    ERROR = 'error',
}

export interface IOrderTransformOptions {
    multiOrder?: boolean;
    recaptcha?: string;
    ignoreBatch?: boolean;
}
